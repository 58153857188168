/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 64.1.3-v202403222322
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqSubscriptionsApiClass {
 
   constructor() {}

  /**
   * @summary List channels and their subscriptions
   * @param {number} [offset=0] - The pagination offset, the index of the first subscription that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of subscriptions that will be returned in this page of results
   */
  public getChannels(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/subscriptions`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetChannelsOutputV1>;
  }

  /**
   * @summary Subscribe to websocket messages published to the specified channel
   */
  public subscribe(
    body: models.SubscriptionInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/subscriptions`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Unsubscribe from the specified channel
   * @param {string} channelId - URI that identifies the channel to delete
   */
  public unsubscribe(
    {
      channelId
    } : {
      channelId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(channelId)) {
      throw new Error("'channelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/subscriptions/${encodeURIComponent(String(channelId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Update a subscription associated with the specified channel
   * @param {string} channelId - URI that identifies the channel the subscriber is updating
   */
  public updateSubscription(
    body: models.SubscriptionUpdateInputV1,
    {
      channelId
    } : {
      channelId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(channelId)) {
      throw new Error("'channelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/subscriptions/${encodeURIComponent(String(channelId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

}


export const sqSubscriptionsApi = new sqSubscriptionsApiClass();
